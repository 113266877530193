<template>
  <div id="article">
    <h1>Our site is getting a little tune up.</h1>
    <div>
      <p>
        We apologize for the inconvenience, but we're performing some
        maintenance. We'll be back up soon. Stay tune!
      </p>
      <p>
        &mdash; INVESTIO - An application of Thai Mutual Funds Performance
        Prediction
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Maintenance",
  beforeRouteEnter(to, from, next) {
    if (process.env.VUE_APP_MAINTAIN_MODE == "yes") {
      next();
    } else {
      next({ name: "Home" });
    }
  },
};
</script>

<style scoped>
body {
  text-align: center;
  padding: 150px;
}
h1 {
  font-size: 40px;
}
body {
  font: 20px Helvetica, sans-serif;
  color: #333;
}
#article {
  display: block;
  text-align: left;
  width: 650px;
  margin: 0 auto;
}
a {
  color: #dc8100;
  text-decoration: none;
}
a:hover {
  color: #333;
  text-decoration: none;
}
</style>
